import { Row, Col, Image } from "antd";
import PentionCalculate from "./components/PentionCalculate";
import cfp_title from "./img/cfp_title.jpg";

function App() {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 2, span: 20 }}
        >
          <Image width="200px" src={cfp_title} />
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 0, span: 24 }}
          md={{ offset: 0, span: 24 }}
          lg={{ offset: 0, span: 24 }}
          xl={{ offset: 2, span: 20 }}
          xxl={{ offset: 2, span: 20 }}
        >
          <PentionCalculate />
        </Col>
      </Row>
    </>
  );
}

export default App;
